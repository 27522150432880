// extracted by mini-css-extract-plugin
export var card = "card-module--card--TTLUX";
export var cardPrimary = "card-module--card--primary--XrJsj";
export var cardSecondary = "card-module--card--secondary--xrECy";
export var cardAccent = "card-module--card--accent--2CPq2";
export var cardDark = "card-module--card--dark--rPzOx";
export var card__header = "card-module--card__header--DUFxi";
export var card__headerPrimary = "card-module--card__header--primary--LO2C5";
export var card__headerSecondary = "card-module--card__header--secondary--hXaPy";
export var card__headerAccent = "card-module--card__header--accent--9W2tc";
export var card__headerDark = "card-module--card__header--dark--iN9mf";
export var card__icon = "card-module--card__icon--uIgwt";
export var card__body = "card-module--card__body--OUq8v";
export var cardWrapper = "card-module--card-wrapper--HdNze";