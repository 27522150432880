// extracted by mini-css-extract-plugin
export var footer__main = "footer-module--footer__main--PnEg2";
export var footer__sub = "footer-module--footer__sub--OamsG";
export var footer__navigation = "footer-module--footer__navigation--lCdN9";
export var footer__textWrapper = "footer-module--footer__text-wrapper--4UpRf";
export var footer__heading = "footer-module--footer__heading--KNt2m";
export var footer__paragraph = "footer-module--footer__paragraph--Dt+vJ";
export var footer__link = "footer-module--footer__link--OQpJa";
export var logo = "footer-module--logo--5nyW8";
export var bacpBadge = "footer-module--bacp-badge--Ay9GN";
export var socialMediaIcon = "footer-module--social-media-icon--rfnX1";
export var sitemap = "footer-module--sitemap--wB747";
export var accreditation = "footer-module--accreditation--mUD7P";
export var legalStrip = "footer-module--legal-strip--AdLuw";