// extracted by mini-css-extract-plugin
export var textWrapper = "typographyLight-module--text-wrapper--C5E7l";
export var title = "typographyLight-module--title--KJXuQ";
export var subtitle = "typographyLight-module--subtitle--bGo9P";
export var heading = "typographyLight-module--heading--nAY1A";
export var subheading = "typographyLight-module--subheading--JDMug";
export var paragraph = "typographyLight-module--paragraph--yflv7";
export var note = "typographyLight-module--note--7Asnz";
export var list = "typographyLight-module--list--Ngcl6";
export var list__item = "typographyLight-module--list__item--MV-E3";
export var orderedList = "typographyLight-module--ordered-list--x-1Bl";
export var orderedList__heading = "typographyLight-module--ordered-list__heading--dEJc9";
export var orderedList__item = "typographyLight-module--ordered-list__item--AyhNc";
export var seperator = "typographyLight-module--seperator--Jxd0e";
export var seperatorIsBold = "typographyLight-module--seperator--is-bold--uZWFc";