// extracted by mini-css-extract-plugin
export var textWrapper = "typographyDark-module--text-wrapper--HoyRq";
export var title = "typographyDark-module--title--6YZMN";
export var subtitle = "typographyDark-module--subtitle--+Wp0o";
export var heading = "typographyDark-module--heading--DmBn6";
export var subheading = "typographyDark-module--subheading--3odMw";
export var paragraph = "typographyDark-module--paragraph--j931a";
export var note = "typographyDark-module--note--xDrLz";
export var list = "typographyDark-module--list--S3Xp1";
export var list__item = "typographyDark-module--list__item--JhJ4m";
export var orderedList = "typographyDark-module--ordered-list--H-LzL";
export var orderedList__heading = "typographyDark-module--ordered-list__heading--1mRlh";
export var orderedList__item = "typographyDark-module--ordered-list__item--WCSXN";
export var seperator = "typographyDark-module--seperator--SPtGo";
export var seperatorIsBold = "typographyDark-module--seperator--is-bold--tR41H";